const handleSliderArrows = function (slider) {
    if (!slider) {
        return
    }
    if (slider.breakpoint === 0) {
        if (slider.opt.slidesToShow >= slider.slides.length) {
            Object.values(slider.arrows).forEach(
                (arrow) => (arrow.style.visibility = 'hidden')
            )
        } else {
            Object.values(slider.arrows).forEach(
                (arrow) => (arrow.style.visibility = 'visible')
            )
        }
    } else {
        const breakpointSettings = slider.opt.responsive.find(
            (el) => el.breakpoint === slider.breakpoint
        ).settings

        if (breakpointSettings.slidesToShow < slider.slides.length) {
            Object.values(slider.arrows).forEach(
                (arrow) => (arrow.style.visibility = 'visible')
            )
        } else {
            Object.values(slider.arrows).forEach(
                (arrow) => (arrow.style.visibility = 'hidden')
            )
        }
    }
}

export default handleSliderArrows

export default {
    breakpoints: {
        xs: 250,
        sm: 768,
        md: 992,
        lg: 1170
    },
    get mq() {
        return {
            xs: `screen and (min-width: ${
                this.breakpoints.xs
            }px) and (max-width: ${this.breakpoints.sm - 1}px)`,
            xsUp: `screen and (min-width: ${this.breakpoints.xs}px)`,
            toSm: `screen and (max-width: ${this.breakpoints.md - 1}px)`,
            sm: `screen and (min-width: ${
                this.breakpoints.sm
            }px) and (max-width: ${this.breakpoints.md - 1}px)`,
            smUp: `screen and (min-width: ${this.breakpoints.sm}px)`,
            smToMd:
                'screen and (min-width: ' +
                this.breakpoints.sm +
                'px) and (max-width: ' +
                (this.breakpoints.lg - 1) +
                'px)',
            md: `screen and (min-width: ${
                this.breakpoints.md
            }px) and (max-width: ${this.breakpoints.lg - 1}px)`,
            toMd: `screen and (max-width: ${this.breakpoints.lg - 1}px)`,
            mdUp: `screen and (min-width: ${this.breakpoints.md}px)`,
            lg: `screen and (min-width: ${this.breakpoints.lg}px)`
        }
    }
}

import axios from 'axios'

/**
 * Get cms blocks html data
 * It uses internal cache to avoid unnecessary requests
 * @param {String} blocksIds separated by comma
 * @returns {Promise<Record<string, string>>}
 */
const getCmsBlocks = async (blocksIds) => {
    const blocksCache = getCmsBlocksCache()
    blocksIds = new Array(blocksIds)

    blocksIds = blocksIds.filter(
        (block) => !Object.keys(blocksCache).includes(block)
    )

    return new Promise((resolve) => {
        if (!blocksIds.length) {
            return resolve(blocksCache)
        }

        ;(async () => {
            const { html } = await fetchCmsBlock(blocksIds)
            if (html) {
                resolve(setCmsBlocksCache(Object.assign(blocksCache, html)))
            }
        })()
    })
}

const fetchCmsBlock = async (blocksIds) => {
    try {
        const { data } = await axios.get(
            BASE_URL + 'base/ajax/getCmsBlocks/?blocks=' + blocksIds.toString()
        )
        return data
    } catch (err) {
        console.log(err)
    }
}

/**
 * Get cms blocks content and make tooltip based on data-tooltip-cms
 * Usage: <span data-tooltip-cms="product_shipment_info">Jak to działa?</span>
 * @param {Array<HTMLElement>} elements
 * @returns void
 */
export const getTooltipDataFromCms = async () => {
    const elements = Array.from(document.querySelectorAll('[data-tooltip-cms]'))

    if (!elements.length) {
        return
    }

    const cmsIds = []

    elements.map(({ dataset }) => {
        if (dataset.tooltipCms && !cmsIds.includes(dataset.tooltipCms)) {
            cmsIds.push(dataset.tooltipCms)
        }
    })

    const blocks = await getCmsBlocks(cmsIds.toString())

    if (!Object.keys(blocks).length) {
        return
    }

    createTooltipsWithCmsData(blocks, elements)
}

const createTooltipsWithCmsData = async (blocks, elements) => {
    const { default: Tooltip } = await import(
        /* webpackChunkName: "bsn-tooltip" */ 'bootstrap.native/dist/components/tooltip-native.esm.js'
    )
    for (const [id, block] of Object.entries(blocks)) {
        elements
            .filter(({ dataset }) => dataset.tooltipCms === id)
            .forEach((element) => {
                element.dataset.toggle = 'tooltip'
                element.dataset.originalTitle = block
                element.dataset.html = true

                if (!element.dataset.placement) {
                    element.dataset.placement = 'bottom'
                }

                if (typeof element.Tooltip === 'undefined') {
                    new Tooltip(element, {
                        template: `<div class="tooltip tooltip--rte" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>`
                    })
                }
            })
    }
}

const getCmsBlocksCache = () => {
    const blocksCache =
        JSON.parse(sessionStorage.getItem('cmsBlocksCache')) || {}
    return blocksCache
}

const setCmsBlocksCache = (data) => {
    sessionStorage.setItem('cmsBlocksCache', JSON.stringify(data))
    return getCmsBlocksCache()
}

export default getCmsBlocks

/* Replace jQuery.extend() */
function extend(out) {
    out = out || {}
    for (var i = 1; i < arguments.length; i++) {
        if (!arguments[i]) continue
        for (var key in arguments[i]) {
            if (arguments[i].hasOwnProperty(key)) out[key] = arguments[i][key]
        }
    }
    return out
}

/* Replace jQuery ready */
function ready(fn) {
    // see if DOM is already available
    if (
        document.readyState === 'complete' ||
        document.readyState === 'interactive'
    ) {
        // call on next available tick
        setTimeout(fn, 1)
    } else {
        document.addEventListener('DOMContentLoaded', fn)
    }
}

function decodeCookieToJson(cname) {
    try {
        return JSON.parse(decodeURIComponent(Cookies.get(cname)))
    } catch (error) {
        return false
    }
}

function cookiesArray() {
    var cookies = {}
    if (document.cookie && document.cookie != '') {
        var split = document.cookie.split(';')
        for (var i = 0; i < split.length; i++) {
            var name_value = split[i].split('=')
            name_value[0] = name_value[0].replace(/^ /, '')
            cookies[decodeURIComponent(name_value[0])] = decodeURIComponent(
                name_value[1]
            )
        }
    }
    return cookies
}

/* Replaces jQuery closest()
 * https://codepen.io/Mobius1/post/native-equivalents-of-jquery-methods#get-the-closest-element-up-the-dom-tree-ancestor-matching-a-selector-35
 */
function closest(el, fn) {
    return (
        el && el !== document.body && (fn(el) ? el : closest(el.parentNode, fn))
    )
}
window.extend = extend
export { extend, decodeCookieToJson, cookiesArray, ready, closest }

import axios from 'axios'

/**
 * Get subcategories for mobile menu
 * @param {String} id
 * @returns {Array<Object>}
 */

export const fetchSubcategories = async (id = null) => {
    try {
        const { data } = await axios.get(
            BASE_URL +
                'catalog_filter/index/getSubcategories' +
                (id ? '?id=' + id : '')
        )
        return data
    } catch (err) {
        console.log(err)
    }
}

import Vue from 'vue'
import GlobalMethods from './utils/GlobalMethods.js'

Vue.config.productionTip = false
Vue.use(GlobalMethods)

const Modal = () =>
    import(
        /* webpackChunkName: "default-modal" */
        /* webpackPrefetch: true */
        './partials/Modal.vue'
    )
const WishList = () =>
    import(
        /* webpackChunkName: "wishlist" */
        /* webpackPrefetch: true */
        './Account/WishList.vue'
    )
const BMICalc = () =>
    import(
        /* webpackChunkName: "bmi-calc" */
        /* webpackPrefetch: true */
        './BMI/BMICalc.vue'
    )
const AddToCart = () =>
    import(
        /* webpackChunkName: "add-to-cart" */
        /* webpackPrefetch: true */
        './Cart/AddToCart.vue'
    )

const RemoveFromCart = () =>
    import(
        /* webpackChunkName: "remove-from-cart" */
        /* webpackPrefetch: true */
        './Cart/RemoveFromCart.vue'
    )
const ProceedToCheckout = () =>
    import(
        /* webpackChunkName: "proceed-to-checkout" */
        /* webpackPrefetch: true */
        './Cart/ProceedToCheckout.vue'
    )
const QtyForm = () =>
    import(
        /* webpackChunkName: "qty-cart" */
        /* webpackPrefetch: true */
        './Cart/QtyCart.vue'
    )
const ProductOut = () =>
    import(
        /* webpackChunkName: "product-out" */
        /* webpackPrefetch: true */
        './Cart/ProductOut.vue'
    )
const SimilarProduct = () =>
    import(
        /* webpackChunkName: "similar-product" */
        /* webpackPrefetch: true */
        './Cart/SimilarProduct.vue'
    )
const ProductRx = () =>
    import(
        /* webpackChunkName: "product-rx" */
        /* webpackPrefetch: true */
        './Cart/ProductRx.vue'
    )
const UpsellModal = () =>
    import(
        /* webpackChunkName: "upsell-modal" */
        /* webpackPrefetch: true */
        './Cart/UpsellModal.vue'
    )
const MiniCart = () =>
    import(
        /* webpackChunkName: "mini-cart" */
        /* webpackPrefetch: true */
        './Cart/MiniCart.vue'
    )
const Pharmacy = () =>
    import(
        /* webpackChunkName: "pharmacy" */
        /* webpackPrefetch: true */
        './Pharmacy/Pharmacy.vue'
    )
const AddToWishlist = () =>
    import(
        /* webpackChunkName: "add-to-wishlist" */
        /* webpackPrefetch: true */
        './Cart/AddToWishlist.vue'
    )
const CopyLinkTooltip = () =>
    import(
        /* webpackChunkName: "tooltip" */
        /* webpackPrefetch: true */
        './Cart/CopyLinkTooltip.vue'
    )
const Tooltip = () =>
    import(
        /* webpackChunkName: "tooltip" */
        /* webpackPrefetch: true */
        './Tooltip/Tooltip.vue'
    )
const CmsWidgets = () =>
    import(
        /* webpackChunkName: "cms-widgets" */
        /* webpackPrefetch: true */
        './CmsWidgets/CmsWidgets.vue'
    )
const ParallelImportTooltip = () =>
    import(
        /* webpackChunkName: "parallel-import-tooltip" */
        /* webpackPrefetch: true */
        './Tooltip/ParallelImportTooltip.vue'
    )
const AddToCartButton = () =>
    import(
        /* webpackChunkName: "add-to-cart-button" */
        /* webpackPrefetch: true */
        './Cart/AddToCartButton.vue'
    )
const NotificationContainer = () =>
    import(
        /* webpackChunkName: "notification" */
        /* webpackPrefetch: true */
        './utils/NotificationContainer.vue'
    )
const MODULE_LIST = {
    'mini-cart': {
        component: MiniCart,
        multiInstances: true,
        appendToInitiator: true
    },
    pharmacy: {
        component: Pharmacy,
        multiInstances: false,
        appendToInitiator: false
    },
    'add-to-cart': {
        component: AddToCart,
        multiInstances: false,
        appendToInitiator: false
    },
    'remove-from-cart': {
        component: RemoveFromCart,
        multiInstances: false,
        appendToInitiator: false
    },
    'proceed-to-checkout': {
        component: ProceedToCheckout,
        multiInstances: false,
        appendToInitiator: false
    },
    'bmi-calc': {
        component: BMICalc,
        multiInstances: true,
        appendToInitiator: true
    },
    'wish-list': {
        component: WishList,
        multiInstances: false,
        appendToInitiator: false
    },
    'qty-cart': {
        component: QtyForm,
        multiInstances: false,
        appendToInitiator: false
    },
    'default-modal': {
        component: Modal,
        multiInstances: false,
        appendToInitiator: false
    },
    'product-out': {
        component: ProductOut,
        multiInstances: false,
        appendToInitiator: false
    },
    'similar-product': {
        component: SimilarProduct,
        multiInstances: false,
        appendToInitiator: false
    },
    'product-rx': {
        component: ProductRx,
        multiInstances: false,
        appendToInitiator: false
    },
    'upsell-modal': {
        component: UpsellModal,
        multiInstances: false,
        appendToInitiator: false
    },
    'add-to-wishlist': {
        component: AddToWishlist,
        multiInstances: true,
        appendToInitiator: false
    },
    'copy-link-tooltip': {
        component: CopyLinkTooltip,
        multiInstances: false,
        appendToInitiator: false
    },
    tooltip: {
        component: Tooltip,
        multiInstances: false,
        appendToInitiator: false
    },
    'cms-widgets': {
        component: CmsWidgets,
        multiInstances: true,
        appendToInitiator: true
    },
    'parallel-import-tooltip': {
        component: ParallelImportTooltip,
        multiInstances: false,
        appendToInitiator: false
    },
    'add-to-cart-button': {
        component: AddToCartButton,
        multiInstances: true,
        appendToInitiator: true
    },
    'notification-container': {
        component: NotificationContainer,
        multiInstances: true,
        appendToInitiator: true
    }
}

let registeredModules = []
const registerModule = (name, el) => {
    if (
        !MODULE_LIST[name]?.multiInstances &&
        registeredModules.includes(name)
    ) {
        return
    }
    if (!MODULE_LIST[name]) {
        console.error(`There is no ${name} component available.`)
        return
    }
    const moduleContainer = document.createElement('div')
    moduleContainer.classList.add(name)
    if (MODULE_LIST[name]?.appendToInitiator) {
        el.appendChild(moduleContainer)
    } else {
        document.body.appendChild(moduleContainer)
    }
    const app = new Vue({
        name: MODULE_LIST[name].component.name + 'App',
        inititator: el,
        render: (h) => h(MODULE_LIST[name].component)
    })
    app.$mount(moduleContainer)
    registeredModules.push(name)
}

const unregisterModule = (moduleName) => {
    if (!name) {
        return
    }
    const index = registeredModules.indexOf(moduleName)
    if (index > -1) {
        registeredModules.splice(index, 1)
    }
}

const registerModulesByDataset = (moduleName = null) => {
    Array.from(
        document.querySelectorAll(
            `[data-vue-init${moduleName ? '="' + moduleName + '"' : ''}]`
        )
    ).forEach((moduleEl) => {
        const moduleName = moduleEl.dataset.vueInit
        registerModule(moduleName, moduleEl)
    })
}

window.addEventListener('DOMContentLoaded', () => {
    registerModulesByDataset()
    if (document.getElementById('catalog-vue')) {
        import(/* webpackChunkName: "catalog" */ './Catalog/index.js').then(
            (module) => {
                module.default()
            }
        )
    }

    // special case for checkout app
    if (document.getElementById('checkout-vue')) {
        import(
            /* webpackChunkName: "checkout" */ './Checkout/index.js'
        ).then(({ initCheckout }) => initCheckout())
    }
})

window.addEventListener(
    'reloadVueModule',
    function ({ detail }) {
        const { moduleName } = detail
        if (moduleName) {
            unregisterModule(moduleName)
            registerModulesByDataset(moduleName)
        }
    },
    false
)

function ProductAtPharmacyPoint(productsToDisallow = []) {
    this.userPharmacy = this.getUserPharmacy()
    this.$bus = window.$apteline.$bus
    this.productFormEl = document.querySelector('#product-form form')
    this.pharmacyPointInfoEl = this.productFormEl
        ? this.productFormEl.querySelector('.product-at-pharmacy-point')
        : null
    this.priceboxEl = this.productFormEl
        ? this.productFormEl.querySelector('.product-shop__cart')
        : null
    this.disallowAtPharmacyPoint = this.productFormEl
        ? !!this.productFormEl.dataset.disallowAtPharmacyPoint || true
        : true
    this.productsToDisallow = productsToDisallow

    this.$bus.$on('PHARMACY_UPDATED', this.onPharmacyUpdate.bind(this))

    this.onPharmacyUpdate(this.userPharmacy)
}

ProductAtPharmacyPoint.prototype = {
    constructor: ProductAtPharmacyPoint,
    getUserPharmacy() {
        let userPharmacy = Cookies.get('userPharmacy')
        if (userPharmacy) {
            userPharmacy = JSON.parse(userPharmacy)
        }
        return userPharmacy
    },
    onPharmacyUpdate(pharmacy) {
        if (
            typeof pharmacy !== 'undefined' &&
            Object.hasOwnProperty.call(pharmacy, 'is_pharmacy_point')
        ) {
            this.userPharmacy = pharmacy

            // switch information about unavailable product
            // for product page
            if (this.productFormEl) {
                const isDisallowed = this.productFormEl.hasAttribute('data-disallow-at-pharmacy-point')
                this.toggleVisibility(
                    pharmacy.is_pharmacy_point && isDisallowed
                )
            }

            // for product lists
            if (this.productsToDisallow) {
                this.toggleProducts(
                    this.productsToDisallow,
                    pharmacy.is_pharmacy_point
                )
            }
        } else {
            // restore addtocart when pharmacy is not available
            if (this.productFormEl) {
                this.toggleVisibility(false)
            }
        }
    },
    toggleVisibility(hide) {
        this.pharmacyPointInfoEl?.classList.toggle('hidden', !hide)
        this.priceboxEl?.classList.toggle('hidden', hide)
        this.productFormEl
            .querySelector('.product-rx')
            ?.classList.toggle('hidden', hide)
    },
    toggleProducts(products = [], hide) {
        Array.from(products).forEach((product) => {
            const classes = Array.from(product.classList)
            const isRefundable = product.hasAttribute('data-refundable')

            // for wishlist items
            if (classes.includes('wishlist-product-item') && !classes.includes('unavailable')) {
              product.classList.toggle('unavailable', hide)
              product.querySelector('.disallowed-in-pharmacy-point')?.classList.toggle('hidden', !hide)
              product.querySelector('.product_addtocart_form')?.classList.toggle('hidden', hide)
              product.querySelector('.wishlist-item-checkbox')?.classList.toggle('hidden', hide)
            }

            // for permanent orders and order history
            const isPermanentOrderItem = classes.includes('permanent-list-item')
            const isHistoryOrderItem = classes.includes('history-order-item')

            if ((isPermanentOrderItem || isHistoryOrderItem) && !classes.includes('unavailable')) {
              product.classList.toggle('unavailable', hide)
              document.querySelectorAll('[data-hide-on-pharmacy-point]').forEach(el => {
                el.classList.toggle('hidden', hide)
              })
              document.querySelectorAll('[data-show-on-pharmacy-point]').forEach(el => {
                el.classList.toggle('hidden', !hide)
              })
            }


            if (
                classes.includes('product-item') &&
                !classes.includes('product-item--unavailable')
            ) {
                product.classList.toggle('product-item--unavailable', hide)

                product
                    .querySelector('.product-item__unavailable')
                    ?.classList.toggle('hidden', !hide)
                product
                    .querySelector('.product-item__out')
                    ?.classList.toggle('hidden', !hide)

                try {
                    // for refundable product we don't have to change the button
                    product
                        .querySelector('.product-item__btn')
                        ?.classList.toggle('hidden', hide && !isRefundable)
                    product
                        .querySelector('.tooltip-max-price-info')
                        ?.classList.toggle('hidden', hide)
                } catch (err) {
                    return
                }
            }
        })
    }
}

export { ProductAtPharmacyPoint }

/**
 * @name iosMultilineSelect
 * @desc Enables multiline <select><options/> on iOS
 */
export const iosMultilineSelect = (elementsList) => {
    if (navigator.userAgent.match(/(iPad|iPhone|iPod touch)/)) {
        for (var i = 0; i < elementsList.length; i++) {
            const select = elementsList[i]

            select.appendChild(document.createElement('optgroup'))
        }
    }
}

import { ProductAtPharmacyPoint } from './page/product/productAtPharmacyPoint'
import { getTooltipDataFromCms } from './utils/getCmsBlocks'

document.addEventListener('DOMContentLoaded', function () {
    require('./app/site.js')
    require('../vue/main.js')

    /**
     * DISALLOW PRODUCTS FOR PHARMACY POINTS
     */
    const productsToDisallow = document.querySelectorAll(
        '[data-disallow-at-pharmacy-point]'
    )
    if (productsToDisallow.length) {
        new ProductAtPharmacyPoint(productsToDisallow)
    }

    /**
     * NEWSLETTER
     */
    if (document.getElementById('newsletter-form')) {
        import(
            /* webpackChunkName: "newsletter" */ './app/newsletter.js'
        ).then(({ newsletterForm }) => newsletterForm())
    }

    /**
     * QUANTITY FORMS
     */
    if (document.querySelector('.qty-form')) {
        import(
            /* webpackChunkName: "qty-form" */ './page/product/productQtyForm.js'
        ).then(({ productQtyForm }) => productQtyForm())
    }

    /**
     * GOOGLE RECAPTCHA
     */
    if (GOOGLE_RECAPTCHA_ENABLED) {
        const GRECAPTCHA_INPUTS = Array.from(
            document.querySelectorAll('.g-recaptcha-response')
        )
        if (GOOGLE_RECAPTCHA_KEY && GRECAPTCHA_INPUTS.length) {
            import(
                /* webpackChunkName: "recaptcha" */ './app/recaptcha.js'
            ).then(({ initRecaptcha }) => initRecaptcha(GRECAPTCHA_INPUTS))
        }
    }

    /**
     * BOOTSTRAP.NATIVE TOOLTIPS
     */
    const TOOLTIPS = Array.from(
        document.querySelectorAll('[data-toggle="tooltip"]')
    )
    if (TOOLTIPS.length) {
        import(
            /* webpackChunkName: "bsn-tooltip" */ 'bootstrap.native/dist/components/tooltip-native.esm.js'
        ).then((BsnTooltip) =>
            TOOLTIPS.forEach((tooltip) => new BsnTooltip.default(tooltip))
        )
    }

    /**
     * BOOTSTRAP.NATIVE COLLAPSE
     */
    const COLLAPSE_ELEMENTS = Array.from(
        document.querySelectorAll('[data-toggle="collapse"]')
    )
    if (COLLAPSE_ELEMENTS.length) {
        import(
            /* webpackChunkName: "bsn-collapse" */ 'bootstrap.native/dist/components/collapse-native.esm.js'
        ).then((BsnCollapse) =>
            COLLAPSE_ELEMENTS.forEach(
                (collapse) => new BsnCollapse.default(collapse)
            )
        )
    }

    /**
     * ICONS
     */
    const ICONS = Array.from(document.querySelectorAll('[data-icon]'))
    if (ICONS.length) {
        import('./app/icons.js').then((Icons) => Icons.default(ICONS))
    }

    /**
     * GLOSSARY / SUBSTANCJE CZYNNE
     */
    if (document.querySelector('.glossary-substance')) {
        import(
            /* webpackChunkName: "glossary" */ './app/glossary.js'
        ).then((glossary) => glossary.default.init())
    }

     /**
     * MOBILE PRODUCTS SLIDERS FOR WIDGETS
     */
      if (document.querySelector('.js-mobile-products')) {
        import(
            /* webpackChunkName: "mobile-sliders" */ './page/widgets/mobile-sliders.js'
        ).then(({ mobileProductsSlider }) => mobileProductsSlider())
    }

    /**
     * Get CMS data for tooltips
     */

    const isAoestaticDependent =
        document.querySelectorAll('.as-placeholder').length > 0
    document.documentElement.addEventListener(
        isAoestaticDependent
            ? 'aoestatic_afterblockreplace'
            : 'DOMContentLoaded',
        () => {
            getTooltipDataFromCms()
        }
    )

     /**
     * MOBILE PRODUCTS SLIDERS FOR WIDGETS
     */
      if (document.querySelector('.js-article-list')) {
        import(
            /* webpackChunkName: "mobile-items" */ './page/mobile-items.js'
        ).then(({ mobileArticleSlider }) => mobileArticleSlider())
    }
})

if (module.hot) {
    module.hot.accept()
}

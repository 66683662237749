import axios from 'axios'
import { EventBus } from './event-bus'
import { getSkinUrl } from '@js/utils/utils.js'

const GlobalMethods = {
    install(Vue) {
        Vue.prototype.$http = axios.create({
            baseURL: BASE_URL
        })

        if (typeof window[LIBRARY_NAME] === 'undefined') {
            window[LIBRARY_NAME] = {}
        }

        window[LIBRARY_NAME]['$bus'] = EventBus
        Vue.mixin({
            computed: {
                BASE_URL: () => BASE_URL,
                GOOGLE_MAPS_KEY: () => GOOGLE_MAPS_KEY
            },
            created() {
                if (typeof window !== 'undefined') {
                    if (this.$options.methods && this.$options.scopeName) {
                        const scope = this.$options.scopeName
                        if (
                            typeof window[LIBRARY_NAME][scope] === 'undefined'
                        ) {
                            window[LIBRARY_NAME][scope] = {}
                        }

                        for (const [name] of Object.entries(
                            this.$options.methods
                        )) {
                            // add only uppercase methods to the global object
                            if (/^[A-Z]/.test(name)) {
                                window[LIBRARY_NAME][scope][name] = this[name]
                            }
                        }
                    }
                }
            },
            methods: {
                getSkinUrl(path) {
                    return getSkinUrl(path)
                }
            }
        })
    }
}

export default GlobalMethods

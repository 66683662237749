/**
 * @name calculateDistance
 * @desc calculate distance between 2 points by Haversine formula
 */
export const calculateDistance = (from, to) => {
    const p = 0.017453292519943295 // Math.PI / 180
    const c = Math.cos
    const a =
        0.5 -
        c((to.lat - from.lat) * p) / 2 +
        (c(from.lat * p) * c(to.lat * p) * (1 - c((to.lng - from.lng) * p))) / 2
    return 12742 * Math.asin(Math.sqrt(a)) // 2 * R; R = 6371 km
}

/**
 * @name loadScript
 * @param {string} url - script url
 * @param {object} [additionalAttrs] - additional HTML element attributes
 */
export const loadScript = (url, additionalAttrs) => {
    const id = window.btoa(url)
    if (!document.getElementById(id)) {
        let script = document.createElement('script')
        script.setAttribute('id', id)
        script.setAttribute('src', url)
        if (additionalAttrs) {
            for (const [key, value] of Object.entries(additionalAttrs)) {
                script.setAttribute(key, value)
            }
        }

        document.body.appendChild(script)
    }
}

/**
 * @name backdropInOut
 * @desc show/hide backdrop layer
 * @param {boolean} state
 */
export const backdropInOut = (state) => {
    const backdrop = document.getElementById('backdrop-main')

    state ? backdrop.classList.add('in') : backdrop.classList.remove('in')
}

export const getSkinUrl = (path) => {
    return `${SKIN_URL}${path}`
}
/**
 * @name formatPrice
 * @param {Number} price
 * @param {object} options
 * @returns {String} formated price
 */
export const formatPrice = (price, options) => {
    options = Object.assign(
        {
            currency: 'zł',
            delimiter: ','
        },
        options
    )
    if (isNaN(price)) {
        return price
    }
    return `${price.toString().replace('.', options.delimiter)} ${
        options.currency
    }`
}
